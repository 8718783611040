
import type { PropType } from 'vue'
import { computed, defineComponent, ref } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmAutoSize from '@/components/shared/autoSize/TmAutoSize.vue'
import type { SizeProp, TabStyle } from '@/definitions/shared/types'
import { tmAutoSizeIgnoreClass } from '@/definitions/shared/autoSize/data'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import _ from 'lodash'

type Tab = {
  name: string;
  counter?: number;
  label?: string;
  to?: string;
  icon?: string;
  disabled?: boolean;
  unread?: boolean;
  tooltip?: string;
}

export default defineComponent({
  name: 'TmTabsNav',
  components: {
    TmDropdownItem,
    TmDropdown,
    TmAutoSize,
    TmButton,
    TmTooltip,
  },
  props: {
    tabs: {
      type: Array as PropType<Tab[]>,
      required: true,
    },
    modelValue: {
      type: String,
      default: null,
    },
    useRouter: {
      type: Boolean,
    },
    noBorder: {
      type: Boolean,
    },
    size: {
      type: String as SizeProp<'' | 'small' | 'medium'>,
      default: '',
    },
    tabsGrow: {
      type: Boolean,
    },
    showCounterZero: {
      type: Boolean,
    },
    hideCounter: {
      type: Boolean,
    },
    tabStyle: {
      type: String as PropType<TabStyle>,
    },
    withDropdown: {
      type: Boolean,
    },
    maxVisibleItems: {
      type: Number,
    },
    hideIcons: {
      type: Boolean,
    },
    tabClass: {
      type: String,
    },
    vertical: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const tabWrapper = ref()
    const hiddenTabsCount = ref()

    const styleClass = computed(() => {
      switch (props.tabStyle) {
        case 'pills': return 'tm-tabs-nav--pills'
        case 'pillsOutlined': return 'tm-tabs-nav--pills tm-tabs-nav--pills--outlined'
        default: return ''
      }
    })

    const moreBtnStyleClass = computed(() => {
      switch (props.tabStyle) {
        case 'pills': return 'tm-tabs-nav__more--pills'
        case 'pillsOutlined': return 'tm-tabs-nav__more--pills tm-tabs-nav__more--pills--outlined'
        default: return ''
      }
    })

    const tabsInDropdown = computed(() => hiddenTabsCount.value > 0 ? props.tabs.slice(-hiddenTabsCount.value) : [])
    const isTabMoreBtnActive = computed(() => tabsInDropdown.value.find((e: Tab) => e.name === props.modelValue))

    const tabComponent = computed(() => props.useRouter ? 'q-route-tab' : 'q-tab')
    const tabWrapperComponent = computed(() => props.withDropdown ? 'tm-auto-size' : 'div')

    const onUpdate = (name: string) => {
      context.emit('update:modelValue', name)
    }

    const qTabsProps = computed(() => {
      const obj: any = {
        align: 'left',
        vertical: props.vertical,
        modelValue: props.modelValue,
        'onUpdate:modelValue': onUpdate,
      }
      return obj
    })

    return {
      tabWrapper,
      tabComponent,
      hiddenTabsCount,
      tabsInDropdown,
      isTabMoreBtnActive,
      styleClass,
      moreBtnStyleClass,
      qTabsProps,
      tabWrapperComponent,
      onUpdate,
      tmAutoSizeIgnoreClass,
      kebabCase: _.kebabCase,
    }
  },
})
